import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import PostList from './post-list';
import MobileBranding from './mobile-branding';
import Pagination from './pagination/pagination';
import SEO from './seo';


export default ({ data, pageContext }) => {
    const { numberOfPages } = pageContext;
    return (
        <Fragment>
            <SEO title={'hello'} keywords={[]}/>
            <MobileBranding/>
            <PostList posts={data.allMdx.edges}/>
            {numberOfPages > 1 && <Pagination pageContext={pageContext}/>}
        </Fragment>
    );
};

export const indexQuery = graphql`
    query indexQuery($skip: Int!, $limit: Int!) {
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    ...post
                }
            }
        }
    }
`;

